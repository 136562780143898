<template>
  <div class="verify-phone-contaner">
    <p class="title">修改绑定手机</p>
    <!-- 步骤条 s -->
    <div class="step">
      <div class="line1"></div>
      <el-steps :active="step" :space="188" align-center>
        <el-step title="验证身份"></el-step>
        <el-step title="绑定新手机"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>
      <div class="line2"></div>
    </div>
    <!-- 步骤条 e -->
    <!-- 步骤一表单 s -->
    <div class="form-box" v-if="step == 1">
      <el-form
        label-width="130px"
        :model="ruleForm"
        :status-icon="false"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
        :hide-required-asterisk="true"
        key="1"
      >
        <!-- 手机号码 -->
        <el-form-item label="手机号码:" required prop="phone">
          <el-input
            type="phone"
            maxlength="20"
            v-model="ruleForm.phone"
            placeholder="请输入"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!-- 手机号码结束 -->
        <!-- 请输入手机验证码： -->
        <el-form-item label="请输入手机验证码:" required prop="code">
          <el-input
            type="number"
            maxlength="20"
            v-model="ruleForm.code"
            placeholder="请输入验证码"
            autocomplete="off"
          ></el-input>
          <button class="getCode f14" @click.prevent="getCode">
            获取验证码
          </button>
        </el-form-item>
        <el-form-item>
          <el-button class="btn cf f16" @click="submitForm('ruleForm')"
            >下一步</el-button
          >
        </el-form-item>
        <!-- 提交结束 -->
      </el-form>
    </div>
    <!-- 步骤一表单 e -->
    <!-- 步骤二表单 s -->
    <div class="form-box2" v-if="step == 2">
      <el-form
        label-width="130px"
        :model="ruleForm2"
        :status-icon="false"
        :rules="rules2"
        ref="ruleForm"
        class="demo-ruleForm"
        :hide-required-asterisk="true"
        key="2"
      >
        <!-- 请输入新手机： -->
        <el-form-item label="请输入新手机:" required prop="newPhone">
          <el-input
            type="phone"
            maxlength="20"
            v-model="ruleForm2.newPhone"
            placeholder="请输入新手机"
            autocomplete="off"
          ></el-input>
          <button class="getCode f14" @click.prevent="getCode2">
            获取验证码
          </button>
        </el-form-item>
        <!-- 请输入新手机：结束 -->
        <!-- 请输入验证码： -->
        <el-form-item label="请输入验证码:" required prop="num">
          <el-input
            type="number"
            maxlength="20"
            v-model="ruleForm2.num"
            placeholder="请输入验证码"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!-- 请输入验证码：结束 -->
        <!-- 提交开始 -->
        <el-form-item>
          <el-button class="btn cf f16" @click="submitForm2('ruleForm2')"
            >提交</el-button
          >
        </el-form-item>
        <!-- 提交结束 -->
      </el-form>
    </div>
    <!-- 步骤一表单 e -->
  </div>
</template>

<script>
export default {
  name: "YoufujichuangVerifyPhone",

  data() {
    var validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      }
      callback();
    };
    var validateNewPhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      }
      callback();
    };
    var validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机验证码"));
      }
      callback();
    };
    var validateNum = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      }
      callback();
    };
    return {
      step: 1, //激活步骤条
      ruleForm: {
        phone: "",
        code: "", //手机验证码
      },
      ruleForm2: {
        num: "", //验证码
        newPhone: "", //新手机号
      },
      rules: {
        phone: [{ validator: validatePhone, trigger: "blur" }],
        code: [{ validator: validateCode, trigger: "blur" }],
      },
      rules2: {
        newPhone: [{ validator: validateNewPhone, trigger: "blur" }],
        num: [{ validator: validateNum, trigger: "blur" }],
      },
      code: "", //获取到的验证码 步骤一
      code2: "", //获取到的验证码 步骤二
    };
  },

  mounted() {},
  created() {
    this.ruleForm.phone = this.$store.state.userInfo.mobile;
  },

  methods: {
    getCode2() {
      let paramData = {
        event: "register",
        userName: this.ruleForm2.newPhone,
      };
      this.$API
        .getCode(paramData)
        .then((res) => {
          if (res.code2 == 1) {
            // this.code2 = 369258;
            // this.ruleForm2.num = 369258;
            this.$message.info('验证码发送成功');
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取验证码
    getCode() {
      let paramData = {
        event: "changePhone",
        userName: this.ruleForm.phone,
      };
      this.$API
        .getCode(paramData)
        .then((res) => {
          if (res.code == 1) {
            // this.code = 369258;
            // this.ruleForm.code = 369258;
            this.$message.info('验证码发送成功');
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //检验短信验证码
    checkCode() {
      let paramData = {};
      this.$API
        .checkCode(paramData)
        .then((res) => {
          if (res.code == 1) {
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 提交 步骤一
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 校验验证码
          let checkCodeParams = {
            captcha: this.ruleForm.code,
            mobile: this.ruleForm.phone,
            event: "changePhone",
          };
          this.$API
            .checkCode(checkCodeParams)
            .then((res) => {
              // if (res.code == 1) {
              //   this.step = 2;
              // } else {
              //   this.$message.info(res.msg);
              // }
              if (res.code == 1) {
                console.log("验证码校验成功");
                this.step = 2;
                // 验证码校验成功后去更改密码
                // let changePwdParams = {
                //   captcha: this.ruleForm.code,
                //   mobile: this.ruleForm.phone,
                //   password: this.ruleForm.password,
                // };
                // this.$API
                //   .forgetPwd(changePwdParams)
                //   .then((res) => {
                //     if (res.code == 1) {
                //       this.$message({
                //         message: "修改成功",
                //         type: "success",
                //       });
                //       setTimeout(() => {
                //         this.$router.push({ name: "login" });
                //       }, 500);
                //     } else {
                //       this.$message.info(res.msg);
                //     }
                //   })
                //   .catch((err) => {
                //     console.log(err);
                //   });
              } else {
                this.$message.info(res.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
          // if (this.ruleForm.code == this.code) {
          //   // 验证码输入正确，继续
          //   this.step = 2;
          // } else {
          //   // 验证码输入错误
          //   this.$message.info("验证码输入错误,请重新输入");
          // }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 提交 步骤二
    submitForm2(formName) {
      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      if (!this.ruleForm2.num) {
        this.$message.info("请输入验证码");
        return;
      }
      if (!this.ruleForm2.newPhone) {
        this.$message.info("请输入新手机号");
        return;
      }
      // let paramData = {
      //   newMobile: this.ruleForm2.newPhone,
      //   newMobileCaptcha: this.ruleForm2.num,
      //   oldMobileCaptcha: this.ruleForm.code,
      // };
      // this.$API
      //   .changePhone(paramData)
      //   .then((res) => {
      //     if (res.code == 1) {
      //       this.$message({
      //         message: "修改成功",
      //         type: "success",
      //       });
      //       this.$store.commit("clearInfo");
      //       setTimeout(() => {
      //         this.$router.push({ name: "login" });
      //       }, 500);
      //     } else {
      //       this.$message.info(res.msg);
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      // 校验验证码
      let checkCodeParams = {
        captcha: this.ruleForm2.num,
        mobile: this.ruleForm2.newPhone,
        event: "changePhone",
      };
      this.$API
        .checkCode(checkCodeParams)
        .then((res) => {
          // if (res.code == 1) {
          //   this.$message.info("成功");
          //   // this.step = 2;
          // } else {
          //   this.$message.info(res.msg);
          // }
          if (res.code == 1) {
            console.log("验证码校验成功");
            // 验证码校验成功后去更改密码
            let changePwdParams = {
              captcha: this.ruleForm.code,
              mobile: this.ruleForm.phone,
              password: this.ruleForm.password,
            };
            this.$API
              .forgetPwd(changePwdParams)
              .then((res) => {
                if (res.code == 1) {
                  this.$message({
                    message: "修改成功",
                    type: "success",
                  });
                  setTimeout(() => {
                    this.$router.push({ name: "login" });
                  }, 500);
                } else {
                  this.$message.info(res.msg);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // if (this.ruleForm.code == this.code) {
      //   // 验证码输入正确，继续
      //   this.step = 2;
      // } else {
      //   // 验证码输入错误
      //   this.$message.info("验证码输入错误,请重新输入");
      // }
      // } else {
      //   console.log("error submit!!");
      //   return false;
      // }
      // if (this.ruleForm.code == this.code) {
      //   // 验证码输入正确，继续
      //   // this.step = 2;
      // } else {
      //   // 验证码输入错误
      //   this.$message.info("验证码输入错误,请重新输入");
      // }
      // } else {
      //   console.log("error submit!!");
      //   return false;
      // }
      // });
    },
  },
};
</script>

<style lang="scss">
.verify-phone-contaner {
  padding: 32px;
  .title {
    padding-bottom: 16px;
    border-bottom: 1px solid #eeeeee;
    font-size: 22px;
    font-weight: bold;
    color: #333333;
  }
  .step {
    .line1 {
      display: inline-block;
      width: 174px;
      height: 8px;
      background: #ff0015;
      border-radius: 90px 90px 90px 90px;
      position: relative;
      top: 22px;
      left: 84px;
    }
    .line2 {
      display: inline-block;
      width: 174px;
      height: 8px;
      background: #dcdcdc;
      border-radius: 90px 90px 90px 90px;
      position: relative;
      top: -51px;
      left: 654px;
    }
    margin: 0 auto;
    margin-top: 30px;
    .el-steps {
      display: flex;
      justify-content: center;
    }
    .is-finish {
      color: #ff0015;
      .el-step__icon {
        border-color: #ff0015;
        background-color: #ff0015;
        color: #ffffff;
      }
      .el-step__line-inner {
        height: 8px;
        background: #ff0015;
        border-radius: 90px 90px 90px 90px;
        border-color: #ff0015;
      }
    }
    .el-step__line {
      height: 8px;
      background-color: #dcdcdc;
    }
    .is-process {
      color: #dcdcdc;
      .el-step__icon {
        border-color: #dcdcdc;
        background-color: #dcdcdc;
        color: #ffffff;
      }
      .el-step__line {
        background-color: #dcdcdc;
      }
    }
    .is-wait {
      color: #dcdcdc;
      .el-step__icon {
        border-color: #dcdcdc;
        background-color: #dcdcdc;
        color: #ffffff;
      }
      .el-step__line {
        background-color: #dcdcdc;
      }
    }
  }
  //   表单
  .form-box {
    margin-top: 95px;
    margin-left: 103px;
    // 性別
    .item {
      display: inline-block;
      margin-left: 20px;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    .item2 {
      margin-left: 56px;
    }
    .el-form-item__content {
      height: 40px !important;
    }
    //input表单样式
    .el-input__inner {
      width: 320px;
      height: 40px;
    }
    //input下边距
    .el-form-item {
      margin-bottom: 32px;
      display: inline-block;
    }
    //label样式
    .el-form-item__label {
      text-align: right;
    }
    //多行文本样式
    .el-textarea__inner {
      width: 400px;
      height: 120px;
      background: #ffffff;
      border-radius: 2px 2px 2px 2px;
    }
    //按钮样式
    .btn {
      width: 214px;
      height: 50px;
      background: linear-gradient(45deg, #ed1b23 0%, #f15f64 100%);
      border-radius: 8px 8px 8px 8px;
      color: #ffffff;
      margin-top: 167px;
      margin-left: 64px;
    }
    .getCode {
      width: 112px;
      height: 40px;
      background: #ff0015;
      border-radius: 8px 8px 8px 8px;
      color: #ffffff;
      position: relative;
      top: -41px;
      left: 339px;
    }
  }
  //   表单
  .form-box2 {
    margin-top: 95px;
    margin-left: 103px;
    // 性別
    .item {
      display: inline-block;
      margin-left: 20px;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    .item2 {
      margin-left: 56px;
    }
    .el-form-item__content {
      height: 40px !important;
    }
    //input表单样式
    .el-input__inner {
      width: 320px;
      height: 40px;
    }
    //input下边距
    .el-form-item {
      margin-bottom: 32px;
      display: inline-block;
    }
    //label样式
    .el-form-item__label {
      text-align: right;
    }
    //多行文本样式
    .el-textarea__inner {
      width: 400px;
      height: 120px;
      background: #ffffff;
      border-radius: 2px 2px 2px 2px;
    }
    //按钮样式
    .btn {
      width: 214px;
      height: 50px;
      background: linear-gradient(45deg, #ed1b23 0%, #f15f64 100%);
      border-radius: 8px 8px 8px 8px;
      color: #ffffff;
      margin-top: 167px;
      margin-left: 64px;
    }
    .getCode {
      width: 112px;
      height: 40px;
      background: #ff0015;
      border-radius: 8px 8px 8px 8px;
      color: #ffffff;
      position: relative;
      top: -41px;
      left: 339px;
    }
  }
}
</style>